const it = {
    add_company: 'Aggiungi azienda',
    businessarea: 'Settore',
    choose_social: 'Scegli social',
    company: 'Azienda',
    choose_company: 'Scegli azienda',
    manage_company: 'Gestione azienda',
    change_company: 'Cambia azienda',
    cover_photo: 'Foto di copertina',
    add_cover_photo: 'Aggiungi immagine di copertina',
    image_recommended: 'Immagine fortemente consigliata',
    company_headquarters: 'Sede azienda',
    headquarters: 'Sede',
    social_network: 'Social Network',
    services_employer: 'Servizi ai dipendenti',
    areas_expertise: 'Settori di competenza',
    look_for_tech_and_creative_talents: 'Cerca talenti tech e creative',
    search_company: 'Inserisci Azienda',
    admin: 'Amministratore',
    employee: 'Dipendente',
    human_resource: 'Risorse umane',
    trainer: 'Trainer',
    talents: 'Talenti',
    offers: 'Offerte',
    team: 'Team',
    delivery: 'Delivery',
    no_requests_for_your_company: 'non ci sono nuove richieste per la tua azienda',
    add_company_message:
        'Il responsabile aziendale riceverà una notifica di match e potrà approvarla oppure rifiutarla',
    upload_title: 'Trascina qui i curriculum oppure',
    upload_title_part_2: 'sfoglia',
    upload_allowed_formats: 'Formati supportati: PDF, DOC, DOCX',
    start_upload: 'Inizia il caricamento',
    upload_search: 'Cerca nome file',
    start_date: 'Data inizio',
    end_date: 'Data fine',
    uploading: 'Caricamento in corso...',
    upload_completed: 'Caricamento completato',
    to_finish: 'al termine',
    upload_of: 'Caricamento del',
    processed: 'Completati',
    state: 'Stato',
    errors: 'Errori',
    file_added_message: 'Hai caricato',
    file_added_message_1: 'curriculum, inizia ora il caricamento',
    working_in: 'Lavoro in',
    file_required: "È necessario caricare almeno un file per iniziare l'upload",
    employer_requests: 'Richieste',
    employer_list: 'Elenco dipendenti',
    request_to_approve: 'richieste da approvare',
    change_role: 'Cambia ruolo',
    remove_employer: 'Dissocia utente',
    assign_role: 'Assegna ruolo',
    company_profile: 'Profilo azienda',
    company_settings: 'Impostazioni azienda',
    open_hr: 'Open HR',
    vat: 'Partita IVA',
    status: 'Stato',
    date: 'Data',
    actions: 'Azioni',
    who: 'Chi',
    what: 'Cosa',
    where: 'Dove',
    how: 'Come',
    remote_or_on_site: 'Ibrido, in remoto o in sede?',
    opportunity_type: 'Tipologia opportunità',
    recruit_talent_cta: 'Vuoi contattare i candidati selezionati per il recruiting?',
    confirm: 'Conferma',
    talent_result_message:
        'Hai trovato {yourDb} talenti nel tuo database e {ourDb} talenti nel database di WH',
    profile_saved_by: 'Profilo salvato da {count} aziende',
    order_by: 'Ordina per',
    active: 'Attivo',
    passive: 'Passivo',
    activate_openhr: 'Attiva Open HR',
    activate_service: 'Attiva il servizio',
    connect_with_providers: 'Colleghi i fornitori con cui collabori',
    extend_talent_search: 'Amplia le ricerche di talenti',
    passage: 'Passaggio {step} di {total}',
    openhr_activation: 'Attivazione Open HR',
    confirm_company_data: 'Conferma i dati della tua azienda',
    confirm_company_data_subtitle:
        'Prendi visione dei dati della tua azienda e assicurati che siano corretti',
    company_name: 'Nome azienda',
    registered_office: 'Sede legale',
    sector: 'Settore',
    your_confirmation: 'Le tue conferme',
    profilation: 'Profilazione Open Wh!',
    profilation_subtitle:
        'Acconsenti al trattamento di profilazione dei tuoi dati personali e particolari, acquisiti Wh! nel contesto del servizio Open HR, unitamente ad altri dati, per dedurre prodotti/servizi di tuo potenziale interesse nonché le tue preferenze, gusti e comportamenti?',
    do_not_accept: 'Non accetto',
    openhr_service: 'Servizio OpenHR',
    acknowledgment: 'Presa visione',
    contract_cta:
        'Per firmare il contratto inserisci la tua password personale Wh! e il codice di conferma che hai ricevuto alla tua email verificata',
    openhr_profiling: 'Profilazione Open HR',
    openhr_privacy: 'Consenso privacy',
    confirmation_code: 'Codice conferma',
    restore_code: 'Recupera codice',
    openhr_search:
        'Inserisci la partita iva preceduta dal codice nazione (es. IT12345678910)',
    db_visibility: 'Concedi la visibilità del mio database di talenti all’esterno',
    db_visibility_subtitle:
        'Ti ricordiamo che verrà concessa solo la visibilità dei macrodati del tuo database',
    all_db_visible: 'Visibilità intero database',
    rejected_profiles: 'Visibilità solo profili scartati',
    professionals: 'professionisti',
    connect: 'Collegati',
    best_plan_for_your: 'Qual è il piano migliore per te?',
    free_plan: 'Fai una prova gratuita. Non serve la carta di credito.',
    select_later: 'Scegli dopo',
    subscription_plan: {
        option_popular: 'Opzione più popolare',
        free: {
            title: 'FREE',
            month_employee: 'per mese & dipendente',
            basic: 'Basic',
            plan_desc:
                'But I must explain to you how all this mistaken idea of denouncing point.',
            functionalities: 'Funzioni principali',
            continue_free: 'Continua con il piano gratuito',
        },
        professional: {
            from: 'da',
            month_employee: 'per mese & dipendente',
            professional: 'Professional',
            plan_desc:
                'But I must explain to you how all this mistaken idea of denouncing point.',
            functionalities: 'Tutte le funzionalità di Basic più:',
            calculate_cost: 'Calcola i costi',
        },
        enterprise: {
            title: 'Prezzo su richiesta',
            enterprise: 'Enterprise',
            plan_desc:
                'But I must explain to you how all this mistaken idea of denouncing point.',
            functionalities: 'Tutte le funzionalità di Professional più:',
            contact_team: 'Contatta il team commerciale',
        },
        calculate_prices: 'Calcola i costi',
        company_dimension: 'Dimensione azienda',
        billing_cycle: 'Ciclo di fatturazione',
        select_plan: 'Scegli il piano',
        open_job_positions: 'Posizioni di lavoro pubblicate',
        open_hr: 'Open HR',
        price_information: 'Informazioni sui prezzi',
        enterprise_plan: 'Piano Enterprise',
        recruiting: 'Recruiting',
        monthly_payment: 'Pagamento mensile',
        yearly: 'annuale',
        buy: 'Acquista',
    },
    not_working_here_anymore: 'Non lavora più qui',
    employee_removed: 'Dipendente rimosso con successo',
    delete_employee: 'Sei sicuro di voler rimuovere questo dipendente?',
    talent_search_detail: {
        contact_info: 'Dati di contatto dell’account di riferimento',
        talent_score: 'Punteggio talento',
        suggestions: 'Suggerimenti',
        inferences_map: 'Mappa inferenze',
        soft_skills: 'Soft Skills',
        profile_matching: 'Corrispondenza del profilo con la tua ricerca',
        overview: 'Overview',
        video_curricula: 'Video curriculum',
        top_hard_skills: 'Top hard skills',
        set_interview: 'Organizza un video colloquio',
        data_from: 'Fonte dei dati',
        reliability: 'Affidabilità',
        personality: 'Personalità',
        potential: 'Potenzialità',
        experience: 'Esperienza',
        leadership: 'Leadership',
        experience_data: 'Dati sulle esperienze',
        sector: 'Settore',
        dimension: 'Dimensione',
        companies: 'Aziende',
        work_companies: 'Aziende dove ha lavorato',
        number_working_experience: 'Numero esperienze lavorative',
        company_change_frequency: 'Frequenza di cambio azienda',
        companyInfo: 'Questo profilo è gestito dall’azienda {company}',
        evaluations: 'Valutazioni',
        addEvaluation: 'Aggiungi valutazione',
        hard_skills: 'Hard skills',
        professional_qualifications: 'Qualifica professionale',
        cv: 'cv',
        save_search: {
            title: 'Dai un nome alla tua ricerca. Potrai salvarla e riprenderla più tardi.',
            search_placeholder: 'Nome della ricerca',
            title_save_talent:
                'Aggiungi talenti alla tua ricerca, selezionali e clicca su salva',
        },
        between_and: 'tra {from} e {to}',
        every_time: 'ogni {year} anni',
        secondary_qualification: 'Qualifica secondaria',
        training: 'Formazione',
        tipology: 'Tipologia',
        summary: 'Riepilogo',
    },
    request_talent_meeting: {
        title: 'Conosci il candidato con un video colloquio',
        subtitle:
            'Inserisci tre opzioni per la video call. Il candidato può proporre altre date.',
        info: 'Cliccando su invia sarà inviata una mail al candidato.',
        insert_message: 'Inserisci un messaggio.',
        send_request: 'Invia e attendi conferma',
        add_info: 'Aggiungi dettagli offerta',
        meeting_requested: 'Meeting richiesto con successo',
    },
    send_confirmation: {
        title: 'Scegli la data del colloquio',
        subtitle:
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.',
        send_request: 'Invia e attendi conferma',
    },
    add_offert_detail: {
        title: 'Dettagli dell’offerta',
        configure_new: 'Configura una nuova offerta',
        duplicate: 'Duplica un’offerta esistente',
        choose_existing: 'Scegli un’offerta esistente',
        next: 'Avanti',
    },
    create_new_offert: {
        title: 'Informazioni (1di2)',
        ads_title_placeholder: 'Titolo dell’annuncio',
        opportunity_type: 'Tipologia opportunità',
        hiring: 'hiring',
        freelance: 'freelance',
        opportunity_features: 'Caratteristiche opportunità',
        less_1_year: 'inferiore ad 1 anno',
        over_1_year: 'superiore ad 1 anno',
        job_place: 'Luogo attività lavorativa',
        remote: 'da remoto',
        in_office: 'in sede',
        mixed: 'misto',
        job_address: 'Informazioni sede',
    },
    create_new_offert_2: {
        title: 'Informazioni (2di2)',
        activate_chat: 'Vuoi attivare il servizio di chat?',
        yes: 'si',
        no: 'no',
        send_and_wait_confirmation: 'Invia e attendi conferma',
    },
    talent_search_history: {
        title: 'Storico ricerche salvate',
        search_placeholder: 'Cerca',
        table: {
            head: {
                name: 'Nome ricerca',
                prifle: 'Profilo cercato',
                data: 'Data',
                saved: 'Salvati',
                conversed: 'Colloquiati',
            },
        },
    },
    draft_candidate: {
        title: 'Stai scartando un candidato, dicci i motivi per migliorare gli algoritmi di intelligenza artificiale.',
        subtitle:
            'Fino a che non abbiamo verificato i dati della tua azienda non potrai pubblicare annunci di lavoro.',
        add_valuation: 'Aggiungi valutazione',
    },
    in_selections: 'In selezione',
    refused: 'Scartato',
    interview_panel: {
        title: 'Colloqui',
        second_interview: 'Secondo colloquio',
        interviews_history: 'Storico colloqui',
        interview: 'Colloquio',
        interview_status: 'Valutazione in corso',
    },
    talent_search_filters: {
        contact_info: 'Informazioni contatto',
        talent_score: 'Punteggio talento',
        suggestions: 'Suggerimenti',
        inferences_map: 'Mappa inferenze',
        experience: 'Esperienze',
        soft_skills: 'Soft skill',
        evaluations: 'Valutazioni',
    },
    contact_info_reference: "Dati di contatto dell'account di riferimento",
    no_employees: 'non ci sono dipendenti nella tua azienda',
    no_jobAnnouncements: 'Non hai annunci da visualizzare.',
    no_jobAnnouncements_create: 'Inizia creando un annuncio.',
    other_companies_message:
        'Potremmo avere delle aziende con un nome simile. Se vedi la tua azienda qui sotto puoi richiedere l’accesso.',
    add_service_employer: 'Aggiungi servizio ai dipendenti',
    add_expertise_area: 'Aggiungi settore di competenza',
    you_are_asking_to_be_paired: 'Stai chiedendo di essere abbinato ad un’azienda.',
    file_uploaded_tooltip:
        'I file elaborati sono i file caricati che Wh elabora per farli analizzare dagli algoritmi di Intelligenza Artificiale',
    file_completed_tooltip:
        'Wh ha processato i file caricati. I file sono stati indicizzati e i risultati compaiono nelle tue ricerche secondo le fasi di elaborazione già eseguite. Il processo prevede più fasi, tra cui ad esempio: analisi mediante algoritmi AI di Wh, analisi mediante OpenAI, Enrichment, Inference map, ecc.',
    file_errors_tooltip:
        'Gli algoritmi di Wh non sono riusciti a leggere i file che hai caricato oppure qualcosa è andato storto',
    we_are_verifying: 'You almost did it. We are verifying your company data.',
    request_sent_to_company_admin:
        'Abbiamo inviato la tua richiesta all’amministratore dell’azienda',
    we_are_verifing_data:
        'Stiamo verificando la tua azienda, a breve potrai pubblicare il tuo primo annuncio di lavoro.',
    you_are_asking_for_a_match:
        'Stai chiedendo di essere abbinato all’azienda {companyName}. L’amministratore aziendale riceverà una notifica di match e potrà approvarla oppure rifiutarla. Assicurati di inviare la richiesta solo se lavori realmente con questa azienda.',
    yes_work_for_this_company: 'Si lavoro per questa azienda',
    wait_for_the_company_administrator_to_approve:
        'Attendi che l’amministratore dell’azienda approva la tua richiesta per avere accesso alle funzionalità azienda.',
    share_offer_page: 'Condivisione pagina offerte',
    share_offer_page_message:
        'Copia e incolla questo link sul tuo sito per condividere la pagina con le tue offerte di lavoro',
    share_offer_page_jobs: 'Condivisione singola offerta',
    share_offer_page_jobs_message:
        'Copia e incolla questo link per condividere l’offerta con potenziali candidati',
    add_company_after_validation:
        'Dopo la convalida puoi scegliere se continuare con il piano gratuito oppure con uno dei nostri piani a pagamento.',
    confirm_to_work_in_this_company:
        'Confermo di avere le autorizzazioni necessarie per operare per questa azienda',
    click_and_confirm_to_work_in_this_company:
        'Cliccando su "inizia" dichiari di operare per questa azienda.',
    your_CV: 'I tuoi cv',
    company_CV: 'I cv della tua azienda',
    wh_CV: 'I cv di WH',
    db_search_type: 'Tipologia database di ricerca',
    rate: 'Tariffa',
    liked_profile: 'Profili che mi piacciono',
    my_offers: 'Le mie offerte',
    no_jobAnnouncements_applications:
        'Non ci sono candidature per questa offerta di lavoro',
    process_status: {
        completed: 'Processato',
        created: 'Creato',
        error: 'Errore',
        loaded: 'Caricato',
        processing: 'In elaborazione',
        uploaded: 'Caricato',
    },
    search_saved: 'Ricerca salvata con successo',
    talent_saved: 'Talenti aggiunti alla ricerca con successo',
    process_by: 'Processato da',
    business_area_public: 'Pubblica Amministrazione',
    business_area_agency: 'Agenzia',
    business_area_company: 'Azienda',
    orderby_correspondence:
        'Clicca qui per vedere i CV che hanno una percentuale di corrispondenza più alta con la tua ricerca',
    oderby_lastupdate: 'Clicca qui vedere i CV più recenti',
};
export default it;
